import React from 'react'
import { Link } from 'gatsby'
import clx from 'classnames'
import {
  element,
  pagination,
  button,
  prevNext,
  list,
  item,
  active as activeClass,
} from './Pagination.module.css'

export interface PageInfo {
  perPage: number
  currentPage: number
  hasNextPage: boolean
  hasPreviousPage: boolean
  pageCount: number
}

interface PaginationProps {
  pageInfo: PageInfo
  basePath: string
  baseTitle: string
  className?: string
}

interface PaginationElement {
  havePrev: boolean
  haveNext: boolean
  pages: { content: string; active: boolean; href?: string }[]
}

function buildPagination(
  pageInfo: PageInfo,
  basePath: string
): PaginationElement {
  const { currentPage, pageCount } = pageInfo
  const havePrev = currentPage === 1
  const haveNext = currentPage === pageCount
  const pages = Array.from({ length: pageCount }, (_, i) => ({
    active: i + 1 === currentPage,
    href: i === 0 ? basePath : `${basePath}page/${i + 1}/`,
    content: `${i + 1}`,
  })).reduce((prev, curr, i, arr) => {
    if (curr.active) return [...prev, curr]
    else if (arr.length > i + 1 && arr[i + 1].active) return [...prev, curr]
    else if (arr.length > i + 2 && arr[i + 2].active) return [...prev, curr]
    else if (i > 0 && arr[i - 1].active) return [...prev, curr]
    else if (i > 1 && arr[i - 2].active) return [...prev, curr]
    else if (i === arr.length - 1) return [...prev, curr]
    else if (i === 0 || i === arr.length - 1) return [...prev, curr]
    else if (prev[prev.length - 1].content !== '...')
      return [...prev, { ...curr, content: '...', href: undefined }]
    else return prev
  }, [] as { content: string; active: boolean; href?: string }[])

  return { havePrev, haveNext, pages }
}

const Pagination: React.FC<PaginationProps> = ({
  pageInfo,
  basePath,
  baseTitle,
  className,
}) => {
  if (!basePath) throw new Error('Pagination has no base path')
  if (!baseTitle) throw new Error('Pagination has no base title')

  const { currentPage } = pageInfo

  const { haveNext, havePrev, pages } = buildPagination(pageInfo, basePath)

  return (
    <nav className={clx(pagination, className)} aria-label="Page navigation">
      <ul className={list}>
        {!havePrev && (
          <li className={item}>
            <Link
              to={
                currentPage - 1 === 1
                  ? basePath
                  : `${basePath}page/${currentPage - 1}/`
              }
              className={clx(button, prevNext)}
              title="Previous Page"
            >
              <span>Previous</span>
            </Link>
          </li>
        )}
        {pages.map(({ active, content, href }, i) => (
          <li key={`${content}-${i}`} className={item}>
            {!!(href && !active) ? (
              <Link
                to={href}
                className={element}
                title={`${baseTitle}${
                  parseInt(content, 10) > 1 ? ` - Page ${content}` : ''
                }`}
              >
                {content}
              </Link>
            ) : (
              <span
                className={active ? clx(button, activeClass) : clx(element)}
              >
                {content}
              </span>
            )}
          </li>
        ))}
        {!haveNext && (
          <li className={item}>
            <Link
              to={`${basePath}page/${currentPage + 1}/`}
              className={clx(button, prevNext)}
              title="Next Page"
            >
              <span>Next</span>
            </Link>
          </li>
        )}
      </ul>
    </nav>
  )
}

export default Pagination
