import React from 'react'
import { GatsbyLinkProps, Link as GLink } from 'gatsby'
import clx from 'classnames'
import styles from './Button.module.css'

interface ButtonProps {
  tag: keyof JSX.IntrinsicElements
}

const Button: React.FC<
  ButtonProps & React.HTMLAttributes<HTMLOrSVGElement>
> = ({ className, tag: Tag = 'button', children, ...props }) => (
  <Tag className={clx(styles.button, className)} {...props}>
    {children}
  </Tag>
)

interface LinkProps extends Omit<GatsbyLinkProps<{}>, 'ref'> {
  active?: boolean
}

const Link: React.FC<LinkProps> = ({
  className,
  active,
  children,
  ...props
}) => (
  <GLink
    className={clx(styles.button, { [styles.active]: active }, className)}
    {...props}
  >
    {children}
  </GLink>
)

export { Link, Button }
