import React from 'react'
import { Link } from 'gatsby'
import clx from 'classnames'

import styles from './Article.module.css'
import Date from '../images/calendar_today-24px.svg'
import Image from './Image'

interface Image {
  childImageSharp?: {
    fluid?: any
  }
}

export interface ArticleData {
  title: string
  excerpt?: string
  slug: string
  uri: string
  date: string
  dateGmt: string
  categories?: { nodes?: { name: string; uri: string }[] }
  featuredImage?: {
    node?: {
      altText?: string
      seo?: {
        metaDesc?: string
      }
      localFile?: Image
      mobileImage?: Image
      desktopImage?: Image
      fullImage?: Image
    }
  }
}

interface ArticleProps {
  data: ArticleData
  card?: boolean
  aside?: boolean
  className?: string
}

const Article: React.FC<ArticleProps> = ({ data, card, aside, className }) => {
  const { title, uri, date, dateGmt, excerpt, categories } = data
  const fluid = data.featuredImage?.node?.localFile?.childImageSharp?.fluid
  const desktopImage =
    data.featuredImage?.node?.desktopImage?.childImageSharp?.fluid
  const mobileImage =
    data.featuredImage?.node?.mobileImage?.childImageSharp?.fluid
  const fullImage = data.featuredImage?.node?.fullImage?.childImageSharp?.fluid
  const sources = [
    mobileImage,
    { ...(card ? fullImage : desktopImage), media: `(min-width: 768px)` },
  ]
  return (
    <article
      className={clx(className, styles.article, {
        [styles.card]: card,
        [styles.aside]: aside,
      })}
    >
      <div className={styles.container}>
        <Link className={styles.nostyle} to={uri} title={title}>
          <h5 className={styles.title}>{title}</h5>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: excerpt || '' }}
          />
        </Link>
        {/* <div className={styles.flex}> */}
        <time className={styles.time} dateTime={dateGmt}>
          <Date /> {date}
        </time>
        {/* </div> */}
      </div>
      {!!(sources && sources.length) && (
        <div className={styles.thumb}>
          {!!(categories && categories.nodes?.length) &&
            categories.nodes.map(({ name, uri }) => (
              <Link key={uri} to={uri} title={name} className={styles.category}>
                {name}
              </Link>
            ))}
          <Link to={uri} title={title}>
            <Image className={styles.thumbImg} fluid={sources} alt={title} />
          </Link>
        </div>
      )}
    </article>
  )
}

export default Article
